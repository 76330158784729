import { FC, memo, useMemo } from 'react';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import differenceInSeconds from 'date-fns/differenceInSeconds';

import { useStyles } from './EvalStatus.styles';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { EvaluationType } from '@modules/EvaluationModule/enums/EvaluationType';
import { differenceInMonths, format } from 'date-fns';

interface Props {
  evaluationDate: string;
  eopResolvedAt?: string | null; // If there is an EOP resolve date, we use it instead of evaluationDate
  status: string;
  typeOfInteraction: EvaluationType;
}

const EvalStatusComponent: FC<Props> = ({ evaluationDate, eopResolvedAt, status, typeOfInteraction }) => {
  const styles = useStyles();

  const today = useMemo(() => new Date(), []);
  const isOverdue = differenceInSeconds(new Date(evaluationDate), today) < 0 && status === EvaluationStatus.Upcoming;

  const { text, style } = useMemo(() => {
    if (status === EvaluationStatus.Closed) return { text: 'Closed', style: styles.evalStatus };
    if (isOverdue) return { text: 'Overdue', style: styles.overdue };

    const timeLeftText = formatDistanceStrict(new Date(evaluationDate), today, {
      roundingMethod: 'floor',
    });

    const isOngoing = status === EvaluationStatus.Ongoing;
    if (isOngoing) {
      const diffInMonths = differenceInMonths(new Date(evaluationDate), today);
      if (diffInMonths === 0) return { text: 'This month', style: styles.ongoing };
      if (diffInMonths === 1) return { text: 'Next month', style: styles.ongoing };
      if (diffInMonths <= -1) return { text: 'Orphaned', style: styles.overdue };

      if (typeOfInteraction === EvaluationType.EndOfProbation) {
        return { text: `EOP in ${timeLeftText}`, style: styles.ongoing };
      } else {
        return { text: `Upcoming evaluation in ${timeLeftText}`, style: styles.ongoing };
      }
    }

    return { text: `Upcoming evaluation in ${timeLeftText}`, style: '' };
  }, [evaluationDate, isOverdue, status, styles.evalStatus, styles.ongoing, styles.overdue, today, typeOfInteraction]);

  const eopDateText = useMemo(() => {
    if (eopResolvedAt) {
      return 'Probation ended on\xA0' + format(new Date(eopResolvedAt), 'MMM d');
    } else {
      return 'Probation ends on\xA0' + format(new Date(evaluationDate), 'MMM d');
    }
  }, [eopResolvedAt, evaluationDate]);

  return (
    <>
      <Typography className={classNames(styles.evalStatus, style)}>{text}</Typography>
      {typeOfInteraction === EvaluationType.EndOfProbation && (
        <Typography variant="body2" whiteSpace="nowrap">
          {eopDateText}
        </Typography>
      )}
    </>
  );
};

export const EvalStatus = memo(EvalStatusComponent);
